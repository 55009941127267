<script>
import { required, helpers } from "vuelidate/lib/validators";
import Modal from "../../../../components/modals/modal-form.vue";
import api from '../../../../helpers/apirest/api';
import AddIndividualMediaButton from "../../../../components/buttons/add-individual-media-button.vue";

import {
    productCategoryComputed,
    storeComputed
} from "@/state/helpers";

const initialState = () => {
    return {
        submitted: false,
        product_category: {
            product_category_parent_id: '',
            name: '',
            color: '',
            slug: '',
            description: '',
            display: null,
            media_id: null,

            stores: []
        }
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
        AddIndividualMediaButton
    },
    computed: {
        ...productCategoryComputed,
        ...storeComputed,
    },
    validations: {
        product_category: {
            name: { required },
            color: { isHexColor: helpers.regex('isHexColor', /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/) },
            product_category_parent_id: {},
            slug: {},
            description: {},
            display: {},
            stores: {
                $each: {
                    id: {},
                    slug: {},
                    menu_order: {},
                }
            }
        }
    },
    methods: {
        async shown() {

            this.$store.dispatch("store/loadList");
            this.$store.dispatch("productCategory/loadList", {
                params: {
                    exclude: this.id ?? null
                }
            });

            if (this.id) {
                const response = await api.get({
                    url: `product-categories/${this.id}`,
                    config: {
                        withLoading: true
                    }
                });
                const product_category = response?.data?.data;
                if(product_category?.stores){
                    const stores = product_category?.stores.map(store => {
                        store.pivot.id = store.pivot.store_id;
                        store.pivot.is_update = true;
                        return store?.pivot;
                    });
                    product_category.stores = stores;
                }
                this.product_category = product_category;
            }
        },
        addRow(){
            this.product_category.stores.push({
                id: '',
                slug: null,
                menu_order: null,
            });
        },
        list(){

        },
        deleteRow(key){
            this.product_category.stores.splice(key, 1);
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const data = this.product_category;
            data.media_id = this.product_category?.media?.id;
            await api.save({
                url: 'product-categories',
                id: this.product_category?.id,
                data: data,
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess");
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
        showUploadModal() {
            this.$bvModal.show(this.uploadModalId);
        },
        handleSelectedImage(image) {
             console.log(image);
             this.product_category.media = image;
        }
    }
};
</script>

<template>
    <Modal :title="'Formulario de categoría'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'extra-xl'">
        <div class="row">

            <div class="col-lg-4 col-md-12 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <h4 class="card-title">General</h4>
                        <p class="card-title-desc">
                            Ingresa los datos generales de la categoría
                        </p>
                        <div class="row">
                            <div class="form-group col-12">
                                <label for="name">Nombre</label>
                                <p class="text-muted font-13">El nombre es como aparece en tu sitio.</p>
                                <input id="name" v-model="product_category.name" type="text" class="form-control" placeholder="Ingresa un nombre"
                                    :class="{ 'is-invalid': $v.product_category.name.$error && submitted }" />
                                <div v-if="!$v.product_category.name.required && submitted" class="invalid-feedback">Nombre es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="slug">Slug</label>
                                <p class="text-muted font-13">El «slug» es la versión amigable de la URL para el nombre. Suele estar todo en minúsculas y contiene solo letras, números y guiones.</p>
                                <input id="slug" v-model="product_category.slug" type="text" class="form-control" placeholder="Ingresa un slug"
                                :class="{ 'is-invalid': $v.product_category.slug.$error && submitted }" />
                                <div v-if="$v.product_category.slug.$error && submitted" class="invalid-feedback">Slug inválido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="color">Color</label>
                                <input id="color" v-model="product_category.color" type="color" class="form-control" placeholder="Ingresa un color"
                                :class="{ 'is-invalid': $v.product_category.color.$error && submitted }" />
                                <div v-if="$v.product_category.color.$error && submitted" class="invalid-feedback">Color inválido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="product_category_parent_id">Categoría padre</label>
                                <p class="text-muted font-13">Asigna un termino principal para crear jerarquías. Por ejemplo, el término «Jazz» sería el principal de «Bebop» y «Big Band».</p>
                                <select v-model="product_category.product_category_parent_id" class="form-control"
                                :class="{ 'is-invalid': $v.product_category.product_category_parent_id.$error && submitted }">
                                <option value="">Ninguna</option>
                                <option v-for="option in productCategories" v-bind:value="option.id" :key="option.id">
                                    {{ option.name }}
                                </option>
                            </select>
                            </div>
                            <div class="form-group col-12">
                                <label for="description">Descripción</label>
                                <p class="text-muted font-13">La descripción no se muestra por defecto; sin embargo, hay algunos temas que pueden mostrarla.</p>
                                <textarea
                                    id="description"
                                    v-model="product_category.description"
                                    class="form-control"
                                    :maxlength="225"
                                    rows="3"
                                    placeholder="Ingresa una descripción"
                                    :class="{ 'is-invalid': $v.product_category.description.$error && submitted }"
                                ></textarea>
                            </div>
                            <div class="col-12">
                                <label>Miniatura</label>
                                <AddIndividualMediaButton :onlyImages="true" v-model="product_category.media"></AddIndividualMediaButton>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-6 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <h4 class="card-title">
                            Tiendas
                        </h4>
                        <p class="card-title-desc">
                            Asocia la categoría con cada una de tus tiendas
                        </p>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Tienda</th>
                                    <th scope="col">Slug</th>
                                    <th scope="col">Orden</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(store, i) in product_category?.stores" :key="i" :set="v = $v.product_category.stores.$each[i]" class="">
                                    <td>
                                        <div class="form-group">
                                            <select v-model="store.id" class="form-control"
                                                :class="{ 'is-invalid': v.id.$error && submitted }">
                                                <option value="">Select</option>
                                                <option v-for="option in stores" v-bind:value="option.id" :key="option.id">
                                                    {{ option.name }}
                                                </option>
                                            </select>                                            
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-group">
                                            <input 
                                                type="text"
                                                v-model="store.slug"
                                                class="form-control"
                                                :class="{ 'is-invalid': v.slug.$error }"
                                                :placeholder="product_category?.slug ?? 'Slug'"
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-group">
                                            <input 
                                                type="number"
                                                v-model="store.menu_order"
                                                class="form-control"
                                                :class="{ 'is-invalid': v.menu_order.$error }"
                                                placeholder="Orden"
                                            />
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <a
                                            href="javascript:void(0);"
                                            class="text-danger"
                                            v-b-tooltip.hover
                                            title="Eliminar"
                                            @click="deleteRow(i)"
                                            v-if="!store?.is_update"
                                        >
                                            <i class="mdi mdi-trash-can font-size-18"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="4" class="text-right">
                                        <a
                                            href="javascript:void(0);"
                                            class="text-success"
                                            @click="addRow()"
                                        >
                                            <i class="mdi mdi-plus-circle font-size-18"></i> Agregar nueva tienda
                                        </a>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>                       
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>